<template>
  <div class="user-verification-wrapper">
    <div class="header">
      <img
        src="@/assets/images/logo/norskhelse_logo.svg"
        alt="logo"
      >
    </div>

    <div class="body">
      <div class="auth-inner m-0 row">
        <div class="align-items-center p-5 pr-md-0 col-md-5">
          <h1 class="font-weight-bolder">
            {{ `${user.user_info.first_name} ${user.user_info.last_name}` }}
          </h1>
          <div class="welcome-text">
            {{ $t(professional_level ? information_type[professional_level].text : 'Before you get started please add details to ypur account') }}
          </div>
          <img
            class="mt-3 w-100"
            src="@/assets/images/pages/auth_medical_care_movn.svg"
            alt=""
          >
        </div>

        <div class="auth-bg px-2 p-lg-5 col-md-7">
          <h5 class="font-weight-bolder mb-2">
            {{ $t('Let us know more about you') }}
          </h5>
          <h5 class="font-weight-bolder">
            {{ $t('Are you a student or a working professional?') }}
          </h5>
          <b-form-group
            label-for="is_doctor"
          >

            <div class="d-flex justify-content-start">
              <b-form-radio
                v-model="professional_level"
                name="professional_level"
                :value="'student'"
                class="mr-3"
              >
                {{ $t('Student') }}
              </b-form-radio>

              <b-form-radio
                v-model="professional_level"
                name="professional_level"
                class="mr-3"
                :value="'professional'"
              >
                {{ $t('Working professional') }}
              </b-form-radio>
              <!-- <b-form-radio
                v-model="professional_level"
                name="professional_level"
                :value="'unemployed'"
              >
                {{ $t('Unemployed') }}
              </b-form-radio> -->
            </div>

          </b-form-group>
          <b-card v-if="professional_level === 'professional'">
            <validation-observer ref="workplaceValidation">
              <h4 class="font-weight-bolder">
                {{ $t("Your county") }}
              </h4>
              <div class="d-flex w-100">
                <div class="col-md-6 px-0 pr-md-1">
                  <b-form-group
                    :label="$t('County')"
                    label-for="County"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('County')"
                      rules="required"
                    >
                      <v-select
                        id="County"
                        v-model="user_county_id"
                        class="bg-light w-100"
                        label="name"
                        :placeholder="$t('County')"
                        :reduce="(county) => county.id"
                        :options="counties"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
              <h4 class="font-weight-bolder">
                {{ $t("Your current workplace") }}
              </h4>
              <div class="d-flex flex-column flex-md-row justify-content-between">
                <div class="col-md-6 px-0 pr-md-1">
                  <b-form-group
                    :label="$t('Position')"
                    label-for="Position"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Position')"
                      rules="required"
                    >
                      <v-select
                        id="Position"
                        v-model="employer.specialty_level_id"
                        class="bg-light"
                        label="name"
                        :placeholder="$t('Position')"
                        :reduce="(category) => category.id"
                        :options="jobCategories"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6 px-0 pr-md-1">
                  <b-form-group
                    :label="$t('Field of work')"
                    label-for="Field of work"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Field of work')"
                      rules="required"
                    >
                      <v-select
                        id="Field of work"
                        v-model="employer.university_specialty_id"
                        class="bg-light"
                        label="name"
                        :placeholder="$t('Field of work')"
                        :reduce="(specialty) => specialty.id"
                        :state="errors.length > 0 ? false : null"
                        :options="specialties"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
              <div class="d-flex flex-column flex-md-row justify-content-between">
                <div class="col-md-6 px-0 pr-md-1">
                  <b-form-group
                    :label="$t('Started working')"
                    label-for="Started working"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Started working')"
                      rules="required"
                    >
                      <b-form-datepicker
                        id="Started working"
                        v-model="employer.started_working"
                        class="bg-light w-auto"
                        placeholder="MM/DD/YYYY"
                        nav-button-variant="muted"
                        :locale="$i18n.locale === 'en' ? 'en-UK' : 'nb-NO'"
                        :state="errors.length > 0 ? false : null"
                        :date-format-options="{
                          year: 'numeric',
                          day: 'numeric',
                          month: 'numeric',
                        }"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
              <h4 class="font-weight-bolder">
                {{ $t('Workplace') }}
              </h4>
              <ChooseWorkplaceForm
                :value="employer"
              />
            </validation-observer>
          </b-card>
          <b-card
            v-if="professional_level === 'student'"
            class="w-50"
          >
            <validation-observer ref="educationValidation">
              <h4 class="font-weight-bolder">
                {{ $t("Your county") }}
              </h4>
              <b-form-group
                :label="$t('County')"
                label-for="County"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('County')"
                  rules="required"
                >
                  <v-select
                    id="County"
                    v-model="user_county_id"
                    class="bg-light w-100"
                    label="name"
                    :placeholder="$t('County')"
                    :reduce="(county) => county.id"
                    :options="counties"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <h4 class="font-weight-bolder">
                {{ $t("Education") }}
              </h4>
              <b-form-checkbox
                v-model="currently_studding"
                class="my-1"
              >
                {{ $t("Currently studding") }}
              </b-form-checkbox>
              <b-form-group
                :label="$t('University')"
                label-for="university-select"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('University')"
                  rules="required"
                >
                  <v-select
                    id="university-select"
                    v-model="education.university_id"
                    class="bg-light"
                    label="name"
                    :placeholder="$t('University')"
                    :reduce="(university) => university.id"
                    :state="errors.length > 0 ? false : null"
                    :options="universities"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                :label="$t('Specialty')"
                label-for="specialty-select"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Specialty')"
                >
                  <v-select
                    id="specialty-select"
                    v-model="education.specialty_id"
                    class="bg-light"
                    :placeholder="$t('Specialty')"
                    :reduce="(specialty) => specialty.id"
                    :state="errors.length > 0 ? false : null"
                    label="name"
                    :options="specialties"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="!currently_studding"
                :label="$t('Graduated')"
                label-for="graduated"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Graduated')"
                  rules="required"
                >
                  <b-form-datepicker
                    id="graduated"
                    v-model="education.graduated"
                    nav-button-variant="muted"
                    class="bg-light w-auto"
                    placeholder="DD/MM/YYYY"
                    :locale="$i18n.locale === 'en' ? 'en-UK' : 'nb-NO'"
                    :state="errors.length > 0 ? false : null"
                    :date-format-options="{
                      year: 'numeric',
                      day: 'numeric',
                      month: 'numeric',
                    }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </validation-observer>
          </b-card>
          <b-form-group
            v-if="professional_level"
            class="d-flex justify-content-center"
          >
            <b-button
              type="submit"
              variant="primary"
              @click="saveInformation"
            >
              {{ $t('Save information') }}
            </b-button>
          </b-form-group>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BFormGroup,
  BFormDatepicker,
  BFormRadio,
  BCard,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { min } from '@validations'
import ChooseWorkplaceForm from '@/components/workplace/ChooseWorkplaceForm.vue'

export default {
  components: {
    BFormGroup,
    BFormDatepicker,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
    BFormCheckbox,
    BCard,
    BButton,
    ChooseWorkplaceForm,
  },
  data() {
    return {
      professional_level: null,
      currently_studding: true,
      user_county_id: null,
      education: {
        graduated: null,
        specialty_id: null,
        university_id: null,
      },
      employer: {
        started_working: null,
        resigned: null,
        specialty_level_id: null,
        university_specialty_id: null,
        workplace_situation_group: 'general_practice',
        workplace_situation_type: null,
        workplace_situation_entity_id: null,
        currently_employer: false,
      },
      selectedCategory: 'general_practice',
      information_type: {
        student: {
          text: 'Before you get started please add your educational information',
          function: this.addEducation,
        },
        professional: {
          text: 'Before you get started please add your professional experience',
          function: this.addWorkplace,
        },
        unemployed: {
          text: 'Before you get started please add details to ypur account',
          function: this.goToHomePage,
        },
      },
      min,
    }
  },
  computed: {
    jobCategories() {
      return this.translate(
        this.$store.getters['jobCategories/jobCategories'],
        this.$i18n.locale,
      )
    },
    counties() {
      return this.translate(
        this.$store.getters['filterData/counties'],
        this.$i18n.locale,
      )
    },
    universities() {
      return this.$store.getters['universities/universities']
    },
    specialties() {
      return this.translate(
        this.$store.getters['specialties/universitySpecialties'],
        this.$i18n.locale,
      )
    },
    user() {
      return this.$store.getters['auth/user']
    },
    customWorkplaces() {
      return this.$store.getters['customWorkplaces/workplaces']
    },
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    'employer.workplace_situation_group'() {
      this.$refs.workplaceValidation.reset()
    },
  },
  created() {
    this.$store.dispatch('filterData/fetchCounties')
    this.$store.dispatch('specialties/fetchUniversitySpecialties')
    this.$store.dispatch('jobCategories/fetchJobCategories')
    this.$store.dispatch('universities/fetchUniversities')
    this.$store.dispatch('workplaces/fetchWorkplaces')
    this.$store.dispatch('customWorkplaces/fetchWorkplaces')
    this.$store.dispatch('hospitals/fetchHospitals')
    this.$store.dispatch('workplaceSituations/fetchWorkplaceSituations')
    this.$store.dispatch('hospitals/fetchHospitals')
  },
  methods: {
    async createWorkplace(workplace) {
      const newWorkplace = await this.$store.dispatch(
        'customWorkplaces/createWorkplace',
        { name: workplace },
      )
      this.$store.dispatch('customWorkplaces/fetchWorkplaces')
      return newWorkplace.id
    },
    addWorkplace() {
      this.$refs.workplaceValidation.validate().then(async success => {
        if (success) {
          const data = {
            county_id: this.user_county_id,
            currently_studding: false,
            employers: [],
          }
          const employer = { ...this.employer }
          // Check for adding a new custom workplace
          if (employer.workplace_situation_group === 'other') {
            // Check for existing workplace
            const isDublicate = this.customWorkplaces.find(workplace => workplace.name === employer.workplace_situation_entity_id)
            if (isDublicate) {
              // Workplace allready exists set id to employer object
              employer.workplace_situation_entity_id = isDublicate.id
            } else {
              // Workplace are new, create it and set id to employer object
              employer.workplace_situation_entity_id = await this.createWorkplace(employer.workplace_situation_entity_id)
            }
          }
          data.employers.push(employer)
          this.$store.dispatch('auth/changeProfileInfo', data).then(() => {
            this.$router.push({ name: 'home' })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('Information changed successfuly!'),
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          })
        }
      })
    },
    addEducation() {
      this.$refs.educationValidation.validate().then(success => {
        if (success) {
          const data = {
            county_id: this.user_county_id,
            currently_studding: this.currently_studding,
            universities: [],
          }
          data.universities.push(this.education)
          this.$store.dispatch('auth/changeProfileInfo', data).then(() => {
            this.$router.push({ name: 'home' })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('Information changed successfuly!'),
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          })
        }
      })
    },
    goToHomePage() {
      this.$router.push({ name: 'home' })
    },
    saveInformation() {
      this.information_type[this.professional_level].function()
    },
  },
}
</script>
